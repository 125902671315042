import {mdiFilePlus} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import {Box} from '@mui/system'
import * as React from 'react'
import {RasterApi} from '../../api/rasters_api'
import {Raster, NewRasterData} from '../../interfaces/raster_interface'
import {RastersTable} from './rasters_table'

interface RastersProps {
}

interface RastersState {
    raster_data: NewRasterData
    rasters?: Raster[]
    uploadProgress: number
    uploading: boolean
}

export class Rasters extends React.Component<RastersProps, RastersState> {
    constructor(props: RastersProps) {
        super(props)
        this.state = {
            raster_data: {
                comment: '',
                varname: '',
            },
            uploadProgress: 100,
            uploading: false,
        }
        this.uploadProgress = this.uploadProgress.bind(this)
        this.load = this.load.bind(this)
        this.submitUploadRaster = this.submitUploadRaster.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const rasters = await RasterApi.listRasters()
            this.setState({rasters: rasters, uploading: false})
        } catch (e) {
            console.error(e)
            alert('Echec du téléchargement des données rasters')
        }
    }

    handleNewFile(file: File) {
        const raster_data = this.state.raster_data
        raster_data!.file = file
        this.setState({raster_data: raster_data})
    }

    async submitUploadRaster(): Promise<void> {
        try {
            this.setState({
                uploading: true,
            })
            if(this.state.raster_data.varname.includes('@')){
                alert('Le nom de la variable de doit pas contenir le symbole : @')
                this.setState({
                    uploading: false,
                })
                return
            }
            await RasterApi.uploadRaster(
                this.state.raster_data,
                this.uploadProgress
            )
            alert('Le raster a été mis en ligne !')
            this.setState({
                raster_data: {
                    file: undefined,
                    comment: '',
                    varname: '',
                },
                uploading: false,
            })
            await this.load()
        } catch (e) {
            console.error(e)
            alert(e)
            this.setState({
                uploading: false,
            })
        }
    }

    uploadProgress(oEvent: any) {
        if (oEvent.lengthComputable) {
            const percentComplete = oEvent.loaded / oEvent.total
            const uploadProgress = Math.round(percentComplete * 100)
            this.setState({uploadProgress: uploadProgress})
        } else {
            console.error(
                'Unable to compute progress information since the total size is unknown'
            )
        }
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <Box sx={{borderBottom: 1}}>
                    <h2>Gestion des rasters</h2>
                </Box>
                <Box sx={{pt: 5, pb: 2}}>
                    <p>Mise en ligne d'un fichier raster</p>
                    <Grid container gap={6} sx={{mt: 2}}>
                        <>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Box
                                    display="flex"
                                    sx={{alignItems: 'center'}}
                                >
                                    <Tooltip
                                        title="Ajouter un raster"
                                        placement="bottom"
                                    >
                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            <Icon
                                                path={mdiFilePlus}
                                                className="app_svg"
                                            />
                                            <input
                                                type="file"
                                                accept=".tif"
                                                hidden
                                                onChange={(event) => {
                                                    // TODO : déplacer une méthode de classe
                                                    if (event.target.files)
                                                        this.handleNewFile(
                                                            event.target.files[0]
                                                        )
                                                }}
                                                required
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Box sx={{pl: 2}}>
                                        <p>
                                            {this.state.raster_data.file
                                                ? this.state.raster_data.file
                                                    .name
                                                : 'Aucun fichier sélectionné'}
                                        </p>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    label="Nom de la variable"
                                    onChange={(event) => {
                                        const raster_data = this.state.raster_data
                                        raster_data.varname = event.target.value.trim()
                                        this.setState({
                                            raster_data: raster_data,
                                        })
                                    }}
                                    required
                                    error={this.state.raster_data.varname.includes('@')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    label="Commentaire"
                                    onChange={(event) => {
                                        // TODO : déplacer une méthode de classe
                                        const raster_data = this.state.raster_data
                                        raster_data.comment = event.target.value
                                        this.setState({
                                            raster_data: raster_data,
                                        })
                                    }}
                                />
                            </Grid>
                            {this.state.raster_data.file ? (
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={this.submitUploadRaster}
                                    >
                                        Mettre en ligne
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </>
                        {this.state.rasters ? (
                            <RastersTable
                                rasters={this.state.rasters!}
                                onRequestReload={this.load}
                            />
                        ) : (
                            <Box
                                sx={{
                                    height: 'calc(100vh - 200px)',
                                    width: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress/>
                            </Box>
                        )}
                    </Grid>
                </Box>
                <Modal open={this.state.uploading}>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backdropFilter: 'blur( 5px )',
                            WebkitBackdropFilter: 'blur( 5px )',
                        }}
                        display="flex"
                        position="fixed"
                    >
                        {this.state.uploadProgress < 100 ? (
                            <Box sx={{width: 0.3, textAlign: 'center'}}>
                                <Typography sx={{color: '#ffffff', pb: 2}}>
                                    Téléchargement du fichier Raster{' '}
                                    {this.state.uploadProgress}%
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={this.state.uploadProgress}
                                />
                            </Box>
                        ) : (
                            <Box sx={{width: 0.3, textAlign: 'center'}}>
                                <Typography sx={{color: '#ffffff', pb: 2}}>
                                    Enregistrement des données
                                </Typography>
                                <LinearProgress/>
                            </Box>
                        )}
                    </Box>
                </Modal>
            </Box>
        )
    }
}
